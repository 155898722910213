<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1">
      <div class="d-flex justify-space-between">
        <p class="font-weight-bold title black--text mb-0">Klien Kami</p>
      </div>
      <v-carousel
          cycle
          height="100"
          hide-delimiter-background
          hide-delimiters
          :continuous="true"
          :show-arrows="false"
        >
          <v-carousel-item>
            <div class="row mt-1 mb-1 d-flex justify-center" >
              <v-col cols="3" class="text-center">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/traveloka.png')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/cedea.jpg')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="mt-4"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="120" :src="require('@/assets/images/brands/royalfoam.webp')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/npure.jpg')" />
                </center>
              </v-col>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="row mt-1 mb-1 d-flex justify-center" >
              <v-col cols="3" class="text-center">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/torch.jpg')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/totebed.png')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/cedea.jpg')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/traveloka.png')" />
                </center>
              </v-col>
            </div>
          </v-carousel-item>
        </v-carousel>
      <!-- <div class="row d-flex justify-center" >
        <v-col cols="3" class="text-center">
          <center>
          <center>
            <v-img class="mt-2"
              :lazy-src="require('@/assets/images/loading.gif')"
              max-width="150" :src="require('@/assets/images/brands/traveloka.png')" />
          </center>
        </v-col>
        <v-col cols="3">
          <center>
            <v-img
            <v-img
              :lazy-src="require('@/assets/images/loading.gif')"
              max-width="100" :src="require('@/assets/images/brands/royalfoam.webp')" />
          </center>
        </v-col>
        <v-col cols="3">
          <center>
            <v-img  class="mt-1"
              :lazy-src="require('@/assets/images/loading.gif')"
              max-width="120" :src="require('@/assets/images/brands/cedea.jpg')" />
          </center>
        </v-col>
        <v-col cols="3">
          <center>
            <v-img
            <v-img
              :lazy-src="require('@/assets/images/loading.gif')"
              max-width="45" :src="require('@/assets/images/brands/npure.jpg')" />
          </center>
        </v-col>
      </div> -->
    </div>
    <!-- DESKTOP -->
    <div v-else>
      <!-- <div class="d-flex justify-space-between">
        <p class="font-weight-bold title black--text">Klien Kami</p>
      </div> -->
        <v-carousel
          cycle
          height="150"
          hide-delimiter-background
          hide-delimiters
          :continuous="true"
          :show-arrows="false"
        >
          <v-carousel-item>
            <div class="row mt-5 mb-5 d-flex justify-center" >
              <v-col cols="3" class="text-center">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/traveloka.png')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/cedea.jpg')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="120" :src="require('@/assets/images/brands/royalfoam.webp')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/npure.jpg')" />
                </center>
              </v-col>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="row mt-5 mb-5 d-flex justify-center" >
              <v-col cols="3" class="text-center">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/torch.jpg')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/totebed.png')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/cedea.jpg')" />
                </center>
              </v-col>
              <v-col cols="3">
                <center>
                  <v-img
                    class="rounded-lg"
                    :lazy-src="require('@/assets/images/loading.gif')"
                    max-width="80" :src="require('@/assets/images/brands/traveloka.png')" />
                </center>
              </v-col>
            </div>
          </v-carousel-item>
        </v-carousel>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
